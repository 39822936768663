@import "../../styles/vars.scss";

.header_sticky {
  position: fixed;
  /* width: 99vh; */
  top: 0;
  z-index: 9999;
  background-color: #151515;
  width: 100%;
  top: 0;
  z-index: 9999;
  background-color: #151515;

  &.sidebar_open {
    background-color: #151515;
    transition: all 0.5s;
    @media screen and (max-width: 768px) {
      background-color: #1b1b1b;
    }
  }
}

.header {
  // padding: 28px 0px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &_right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 26px;

    position: relative;
    z-index: 2000;

    @media (max-width: 420px) {
      gap: 16px;
    }
  }

  .menuBtn {
    width: 30px;
    height: 30px;
    position: relative;
    z-index: 20;
    overflow: hidden;
    cursor: pointer;

    .bar {
      width: 30px;
      height: 4px;
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 4px;
      transform: translate(-50%, -50%);
      background-color: white;
      transition: all 0.3s ease-in-out;
    }

    .bar:nth-of-type(2) {
      top: calc(50% - 9px);
    }

    .bar:nth-of-type(3) {
      top: calc(50% + 9px);
    }

    &.active .bar:nth-of-type(1) {
      display: none;
    }

    &.active .bar:nth-of-type(2) {
      top: 50%;
      transform: translate(-50%, 0%) rotate(45deg);
    }

    &.active .bar:nth-of-type(3) {
      top: 50%;
      transform: translate(-50%, 0%) rotate(-45deg);
    }
  }
}

@media (max-width: 420px) {
  .header {
    // padding: 27px 0;
  }
}
