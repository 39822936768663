@import "../../../styles/vars.scss";

.list {
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 47px;
  margin-bottom: 94px;

  @media (max-width: 420px) {
    gap: 0px;
    margin-bottom: 0;
  }
}

.form {
  margin-top: 15px;
  overflow: auto;
  margin: 0 15px;
  width: 100%;

  h1 {
    color: #fff;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    // margin-bottom: 25px;

    & > img {
      display: none;
    }
  }

  .type {
    font-size: 2.5em;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    color: $darkGrey;
    text-transform: uppercase;
    cursor: pointer;
    transition: font-size 0.2s, color 0.2s;

    @media (max-width: 420px) {
      font-size: 32px;
    }

    &:hover {
      color: #fff;
    }

    &.isActive {
      color: #fff;
      font-size: 48px;
    }

    @media (max-width: 420px) {
      &.isActive {
        color: #fff;
        font-size: 32px;
      }
    }
  }

  .inputs {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 16px;

    textarea {
      grid-column: span 2 / span 2;
      height: 95px;
    }
  }

  input,
  select,
  textarea {
    border-radius: 4px;
    border: 1px solid $border;
    background-color: $bg-color;
    padding-left: 22px;
    padding-top: 20px;
    padding-bottom: 17px;
    outline: none;
    font-size: 20px;
    color: $white;
    font-weight: 300;

    &::placeholder {
      color: $placeholder-color;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .form {
    h1 {
      width: 579px;
      margin-bottom: 0;
      position: relative;
      display: flex;
      align-items: flex-end;

      & > img {
        display: block;
        position: absolute;
        left: 322px;
      }
    }

    p {
      margin: 47px 0;
    }

    input,
    select,
    textarea {
      width: 100%;
      background: $sideBar-bg;
    }
  }
}

@media (max-width: 420px) {
  .form {
    h1 {
      & > img {
        left: 345px;
        bottom: 5px;
      }
    }
  }
}

@media (max-width: 431px) {
  .form {
    margin: 0;

    h1 {
      width: 390px;
      font-size: 42px;

      & > img {
        left: 352px;
      }
    }

    p {
      margin: 10px 0;
    }
  }
}

@media (max-width: 376px) {
  .form {
    height: 80vh;
    overflow: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
    }

    p {
      font-size: 2.3rem;
    }
  }
}
