@import "../../../styles/vars.scss";

.button {
  @media (max-width: 431px) {
    width: 100% !important;
    max-width: none !important;
  }
}

.form {
  overflow: auto;
  width: 100%;
  padding: 0 15px;
  h1 {
    color: #fff;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 35px;
    max-width: 579px;
  }

  .inputs {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 16px;
    column-gap: 36px;

    textarea {
      grid-column: span 2 / span 2;
      height: 95px;
    }
  }

  input,
  select,
  textarea {
    border-radius: 4px;
    border: 1px solid $border;
    background-color: $bg-color;
    padding-left: 22px;
    padding-top: 20px;
    padding-bottom: 17px;
    outline: none;
    font-size: 20px;
    color: $white;
    font-weight: 300;
    resize: none;

    &::placeholder {
      color: $placeholder-color;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: 769px) {
  .form {
    width: 100%;
    padding: 16px;
    background: $sideBar-bg;
    height: 100%;

    // & > h1 {
    //   width: 579px;
    // }

    .inputs {
      margin-top: 60px;
      margin-bottom: 20px;
    }

    input,
    select,
    textarea {
      background-color: $sideBar-bg;
    }
  }
}

@media (max-width: 431px) {
  .form {
    h1 {
      font-size: 42px;
    }

    .inputs {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

.textarea {
  height: auto;
  overflow: hidden;
  min-height: 87px;
  width: 100%;
}
