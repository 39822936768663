@import "../../styles/vars.scss";

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 1010;

  display: flex;
  justify-content: flex-end;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;

  &.active {
    pointer-events: all;
    opacity: 1;
  }

  .content {
    background: $bg-color;
    padding-top: 140px;
    height: 100%;
    width: 50%;
    min-width: 1080px;
    transform: translateX(100%);
    transition: transform 0.6s ease-in-out;

    padding-left: 90px;
    // padding-right: 154px;
    display: flex;
    justify-content: center;

    @media (max-width: 420px) {
      top: 100px;
    }

    @media screen and (max-width: 1081px) {
      min-width: 100%;
    }

    &.active {
      transform: translateX(0%);
    }
  }
}

@media (max-width: 768px) {
  .sidebar {
    .content {
      width: 100%;
      padding-left: 8px;
      background: $sideBar-bg;
    }
  }
}

@media (max-width: 431px) {
  .sidebar {
    .content {
      justify-content: normal;
      padding-right: 0;
    }
  }
}

@media (max-width: 391px) {
  .sidebar {
    .content {
      justify-content: normal;
    }
  }
}
