@import "../../styles/vars.scss";
@import "../../styles/mixins/utility.scss";

.insole {
  position: relative;
  margin-bottom: 20px;
}

.asdf {
  transform: translate(-50%, 0) !important;
  transition: 1s;
}

.first {
  @media (max-width: 420px) {
    transform: translate(-50%, 60px) !important;
  }
}

.three {
  @media (max-width: 420px) {
    transform: translate(-50%, -60px) !important;
  }
}

.firstInsoleImg {
  @include basicInsoleImage;
  transform: translate(-50%, -100px);

  @media (max-width: 420px) {
    transform: translate(-50%, -50px);
  }

  // @media (max-width: 420px) {
  //   transform: translate(-50%, -50px);
  // }

  clip-path: polygon(
    2% 83%,
    5% 87%,
    9% 88%,
    14% 88%,
    21% 86%,
    27% 83%,
    35% 80%,
    43% 75%,
    49% 71%,
    56% 66%,
    60% 59%,
    66% 50%,
    74% 40%,
    83% 31%,
    90% 25%,
    96% 19%,
    97% 15%,
    97% 12%,
    96% 10%,
    93% 9%,
    84% 10%,
    74% 16%,
    62% 17%,
    53% 18%,
    46% 23%,
    30% 49%,
    13% 64%,
    4% 74%,
    2% 78%
  );

  z-index: 4;
  background-image: url("../../assets/img/rubber_insole.png");

  &:hover {
    transform: translate(-50%, -100px) !important;
    transition: 1s;
  }

  @media (max-width: 420px) {
    &:hover {
      transform: translate(-50%, -20px) !important;
      transition: 1s;
    }
  }
}

.colorOrange {
  color: $orange;
}

.insoleCaptionSecondHover {
  color: white;
}

.insolePathHover {
  stroke: $orange !important;
}

.threeInsoleImg {
  @include basicInsoleImage;
  transform: translate(-50%, 100px);

  @media (max-width: 420px) {
    transform: translate(-50%, 50px);
  }
  clip-path: polygon(
    2% 85%,
    10% 91%,
    30% 84%,
    42% 77%,
    57% 66%,
    67% 53%,
    77% 43%,
    89% 32%,
    97% 24%,
    98% 15%,
    95% 9%,
    88% 10%,
    77% 14%,
    66% 17%,
    55% 18%,
    49% 21%,
    43% 28%,
    31% 49%,
    17% 62%,
    5% 72%,
    3% 78%
  );

  z-index: 2;
  top: 200px;
  background-image: url("../../assets/img/rechin_insole.png");

  &:hover {
    transform: translate(-50%, 100px) !important;
    transition: 1s;

    .insoleCaptionFirst,
    .insolePath {
      color: $orange;
    }

    .insoleCaptionSecond {
      color: white;
    }

    .insolePath {
      stroke: $orange;
    }
  }

  @media (max-width: 420px) {
    &:hover {
      transform: translate(-50%, 20px) !important;
      transition: 1s;
    }
  }
}

.secondInsoleImg {
  @include basicInsoleImage;
  transform: translate(-50%);
  z-index: 3;
  top: 100px;
  background-image: url("../../assets/img/insole_glue.png");
}

.imagesContainer {
  position: relative;
  min-height: 774px;
  top: 90px;

  @media (max-width: 1380px) {
    top: 150px;
  }

  @media (max-width: 665px) {
    min-height: 438px;
    top: 0;
  }

  @media (max-width: 420px) {
    min-height: 395px;
    top: 0;
  }
}

.insoleImage {
  position: absolute;
  left: 50%;
}

.test1 {
  transform: translate(-50%, -100px) !important;
  transition: 1s;

  @media (max-width: 420px) {
    transform: translate(-50%, -30px) !important;
    transition: 1s;
  }
}

.test2 {
  transform: translate(-50%, 100px) !important;
  transition: 1s;

  @media (max-width: 420px) {
    transform: translate(-50%, 30px) !important;
    transition: 1s;
  }
}

.insole::before,
.insole::after {
  content: "";
  position: absolute;
  display: block;
  top: 30%;
  left: 0;
  width: 100%;
  height: 1px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='8%2c18' stroke-dashoffset='45' stroke-linecap='round'/%3e%3c/svg%3e");
  z-index: 1;

  @media (max-width: 768px) {
    top: 21%;
  }
}

.insole::after {
  transform: rotate(-15deg);
}

.insoleImageBlock {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 260px;

  @media (max-width: 768px) {
    margin-top: 119px;
    margin-bottom: 186px;
  }

  @media (max-width: 420px) {
    margin-bottom: 74px;
  }
}

.InsoleImage {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
  z-index: 2;

  @media (max-width: 1390px) {
    width: 600px;
  }
}

.insoleBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-end;
  z-index: 3;
  cursor: default;
}

.insoleTextTop {
  justify-content: end;
}

.insoleTextRight {
  margin-left: auto;
  margin-top: -8.8rem;
  align-items: start;

  .insoleText {
    max-width: 360px;
  }
}

.insoleCaptionBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.insoleCaption {
  position: relative;
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0em;
  color: $darkGrey;
  transition: 0.5s;
}

.insolePath {
  position: absolute;
  stroke: rgba(255, 255, 255, 0.2);
  transition: 0.5s;
  z-index: 1;
}

.insoleCaptionFirst,
.insoleCaptionSecond {
  font-size: 36px;
  transition: 0.5s;
  text-align: right;

  @media (max-width: 768px) {
    margin-left: auto;
    text-align: right;
  }

  @media (max-width: 420px) {
    text-align: right;
    font-size: 24px;
  }
}

.start {
  align-items: flex-start;

  @media (max-width: 420px) {
    align-items: flex-end;
  }
}

.insoleCaption:hover {
  .insoleCaptionFirst,
  .insolePath {
    color: $orange;
  }

  .insoleCaptionSecond {
    color: white;
  }
}

.insoleCaption:hover .insolePath {
  stroke: $orange;
}

.insoleText {
  position: relative;
  display: block;
  margin-top: 15px;
  max-width: 310px;
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  color: white;
  text-align: end;

  @media (max-width: 991px) {
    margin-left: 38px;
  }
}

@media (min-width: 375px) {
}

@media (max-width: 991px) {
  .insoleTextTop {
    max-width: 430px;
    display: flex;
    align-items: center;

    .insoleCaptionBlock {
      display: block;
      text-align: right;
    }

    .insoleText {
      text-align: right;
      margin-left: auto;
    }
  }

  .insoleTextRight {
    max-width: 430px;
    margin-top: 2rem;
    align-items: flex-end;
    padding-right: 15px;

    .insoleCaption,
    .insoleText {
      text-align: right;
    }
  }

  .insoleTextBottom {
    max-width: 350px;
    align-items: flex-start;
    margin-top: 2rem;
    margin-left: 7rem;
  }

  // .insoleImageBlock {
  //   padding-top: 6.6rem;
  // }

  .insoleCaption {
    font-size: 36px;
    line-height: 44px;
    max-width: 430px;
  }

  .pathTop,
  .pathRight,
  .pathBottom {
    display: none;
  }

  .pathTopm,
  .pathRightm,
  .pathBottomm {
    display: block;
  }

  .pathTopm {
    width: 152px;
    top: 20px;
    right: -180px;
  }

  .pathRightm {
    width: 86px;
    top: -171px;
    right: 35px;
  }

  .pathBottomm {
    width: 86px;
    top: -120px;
    left: -100px;
  }
}

@media (max-width: 640px) {
  .insoleCaption {
    font-size: 28px;
    line-height: 40px;
  }

  .insoleText {
    font-size: 16px;
  }

  .InsoleImage {
    margin-top: 66px;
  }
}

@media (max-width: 576px) {
  .insoleCaption {
    width: 284px;

    font-size: 24px;
    line-height: 34px;
  }

  .insoleText {
    width: 250px;
    font-size: 13px;
    text-align: left;
    margin: 0;
  }

  .pathTopm {
    right: -100px;
  }

  .InsoleImage {
    max-width: 95%;
  }

  .pathTopm {
    width: 83px;
    transform: rotate(33deg);
  }
}

@media (max-width: 420px) {
  .insoleTextBottom {
    margin-left: 3rem;
    margin-top: 5rem;
  }

  .insoleTextTop {
    .insoleCaption {
      width: 370px;
    }

    .insoleCaptionBlock {
      margin-right: 78px;
    }

    .insoleText {
      margin-left: -16px;
      width: 225px;
    }

    .pathTopm {
      right: 44px;
      width: 77px;
      top: 77px;
    }
  }

  .pathRightm {
    right: 33;
    transform: scale(1, -1) rotate(45deg);
  }

  .pathBottomm {
    width: 22px;
    top: -113px;
    left: -39px;
  }

  .insoleTextRight {
    margin-top: 4rem;
  }
}

@media (max-width: 490px) {
  .pathTopm {
    right: -41px;
    top: 40px;
  }
}

@media (min-width: 992px) {
  .insoleTextTop {
    max-width: 515px;
    margin-bottom: -8.8rem;
  }

  .insoleTextBottom {
    max-width: 350px;
    align-items: flex-end;
    margin-top: -6rem;
    margin-left: 0;
  }

  .insoleTextRight {
    max-width: 430px;
    margin-top: -14rem;
    margin-right: 6%;
    align-items: flex-start;

    .insoleCaption,
    .insoleText {
      text-align: left;
    }
  }

  .insoleTextBottom {
    .insoleText {
      text-align: right;
    }
  }

  .insoleImageBlock {
    padding-top: 14rem;
  }

  .pathTop,
  .pathRight,
  .pathBottom {
    display: block;
  }

  .pathTop {
    width: 152px;
    top: 50px;
    right: -200px;
  }

  .pathRight {
    width: 105px;
    top: -10px;
    left: -130px;
  }

  .pathBottom {
    width: 86px;
    top: -120px;
    right: -35px;
    left: auto;
  }

  .pathTopm,
  .pathRightm,
  .pathBottomm {
    display: none;
  }
}

.middle_text {
  @media (max-width: 420px) {
    text-align: left;
    margin-right: 54px;
  }
  @media (max-width: 768px) {
    text-align: left;
  }
}

.desc2 {
  @media (max-width: 768px) {
    text-align: left;
    margin-right: auto;
    margin-left: 0;
  }
}
