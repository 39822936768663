@import "../../styles/vars.scss";
.slider {
  display: flex;
  overflow: hidden;
  min-width: 400px;
  height: 265px;
  list-style: none;
  transition: 1s;
  position: relative;

  @media (max-width: 480px) {
    height: 165px;
  }

  ul {
    animation: slide 4s infinite;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
  }

  li {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 1s ease-in-out;
  }
}

.slider li.active {
  opacity: 1;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-100%);
  }

  45% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-200%);
  }

  70% {
    transform: translateX(-200%);
  }

  75% {
    transform: translateX(-300%);
  }

  95% {
    transform: translateX(-300%);
  }

  100% {
    transform: translateX(-400%);
  }
}

.button_list {
  display: flex;
  gap: 16px;
  list-style: none;
}

.button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  width: 31px;
  height: 9px;
  border-radius: 4px;
  background-color: rgba(49, 49, 49, 1);
}

.button_active {
  background-color: rgba(255, 107, 0, 1);
  width: 90px;
}

main {
  position: relative;
  margin-top: 128px !important;

  &::before,
  &::after {
    content: "";
    position: absolute;
    display: block;
    top: 30%;
    left: 0;
    width: 100%;
    height: 1px;
    background-image: url("data:image/svg+xml,%3csvg width=%27100%25%27 height=%27100%25%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3crect width=%27100%25%27 height=%27100%25%27 fill=%27none%27 stroke=%27%23333%27 stroke-width=%274%27 stroke-dasharray=%278%2c18%27 stroke-dashoffset=%2745%27 stroke-linecap=%27round%27/%3e%3c/svg%3e");
    z-index: 1;
  }

  &::after {
    transform: rotate(-15deg);
    z-index: 0;
    translate: -307px 248px;
    width: 129%;
    @media (max-width: 1920px) {
      width: 100%;
    }

    @media (max-width: 1400px) {
      width: 119%;
    }

    @media (max-width: 1080px) {
      width: 85%;
      translate: 74px 161px;
    }

    @media (max-width: 769px) {
      transform: rotate(-16deg);
      z-index: 0;
      translate: 22px 159px;
      width: 95%;
    }

    @media (max-width: 480px) {
      translate: 22px 90px;
      width: 92%;
    }
  }

  &::before {
    transform: rotate(13deg);
    translate: 15px 18px;
    width: 104%;

    @media (max-width: 3090px) {
      width: 100%;
    }

    @media (max-width: 1500px) {
      width: 98%;
    }

    @media (max-width: 1400px) {
      width: 95%;
    }

    @media (max-width: 1000px) {
      transform: rotate(50deg);
      translate: 39px -22px;
      width: 109%;
    }

    @media (max-width: 480px) {
      translate: -66px -31px;
      width: 142%;
    }

    @media (max-width: 1080px) {
      width: 82%;
      translate: 71px 77px;
    }
  }
}

.wrapper {
  :global {
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      bottom: 85px;
      left: 70.8%;
      display: flex;
      width: 100%;

      @media (max-width: 1420px) {
        left: 885px;
      }

      @media (max-width: 1275px) {
        left: 714px;
      }

      @media (max-width: 1164px) {
        bottom: 18px;
        left: 642px;
      }

      @media (max-width: 1040px) {
        left: 550px;
      }
    }

    span.swiper-pagination-bullet-active {
      width: 90px;
      background-color: $orange;
    }

    .mySwiper {
      height: 840px;

      // @media (max-width: 1670px) {
      //   height: 1040px;
      // }

      @media (max-width: 1530px) {
        height: 750px;
      }
      @media (max-width: 1164px) {
        height: 843px;
      }
      :global {
        .swiper-pagination-fraction,
        .swiper-pagination-custom,
        .swiper-horizontal > .swiper-pagination-bullets,
        .promo-slider_wrapper__9AKN2
          .swiper-pagination-bullets.swiper-pagination-horizontal {
          bottom: 0;
          left: 0;
          display: flex;
          /* width: 100%; */
          width: 300px;
          height: 20px;
          z-index: 100000;
          top: 240px;

          @media (max-width: 480px) {
            top: 200px;
          }
        }
      }

      @media (max-width: 768px) {
        .mySwiper {
          height: auto;
        }
      }
    }
  }

  @media (max-width: 768px) {
    height: auto;
  }

  .slide {
    .center {
      display: flex;
      gap: 195px;
      align-items: center;
    }

    .title {
      color: $white;
      font-size: 112px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;

      & > p {
        text-align: right;
        color: $darkGrey_v2;
        // margin-right: 10px;
      }

      @media only screen and (max-width: 1530px) {
        font-size: 107px;
        & > p {
          margin-right: 109px;
          font-size: 105px;
        }
      }

      @media (max-width: 1420px) {
        font-size: 100px;

        & > p {
          font-size: 80px;
          margin-right: 104px;
        }
      }

      @media (max-width: 1366px) {
        font-size: 88px;
      }

      @media (max-width: 1275px) {
        font-size: 90px;
      }

      @media (max-width: 1164px) {
        font-size: 84px;

        & > p {
          font-size: 68px;
          margin-right: 68px;
        }
      }

      @media (max-width: 1100px) {
        font-size: 78px;

        & > p {
          font-size: 42px;
        }
      }

      @media (max-width: 1030px) {
        font-size: 71px;
      }

      @media (max-width: 960px) {
        font-size: 52px;

        & > p {
          font-size: 30px;
        }
      }

      @media (max-width: 840px) {
        & > p {
          margin-right: 23px;
          font-size: 26px;
        }
      }

      @media (max-width: 768px) {
        & > p {
          font-size: 40px;
          position: relative;
          top: -6px;
        }
      }

      @media (max-width: 670px) {
        & > p {
          font-size: 35px;
          position: relative;
          top: -8px;
        }
      }

      @media (max-width: 598px) {
        & > p {
          top: -88px;
          right: 0;
          font-size: 28px;
        }
      }

      @media (max-width: 440px) {
        & > p {
          right: 10px;
        }
      }

      @media (max-width: 490px) {
        font-size: 37px;

        & > p {
          font-size: 20px;
          top: -6px;
          right: -24px;
        }
      }

      @media (max-width: 480px) {
        & > p {
          top: -6px;
        }
      }

      @media (max-width: 370px) {
        font-size: 50px;

        & > p {
          font-size: 20px;
        }
      }
    }

    &_desc__left {
      position: relative;
      bottom: 775px;
      width: 473px;

      &:hover {
        & > h1 {
          color: $white;

          & > span {
            color: $orange;
          }
        }
      }

      @media (max-width: 1000px) {
        bottom: 605px;
      }

      @media (max-width: 502px) {
        bottom: 441px;
      }

      @media (max-width: 480px) {
        bottom: 382px;
      }
    }

    &_desc__right {
      position: relative;
      bottom: 663px;
      width: 391px;
      left: 71%;

      @media (max-width: 1420px) {
        bottom: 760px;
        left: 68%;
      }

      @media (max-width: 1275px) {
        left: 58%;
      }

      @media (max-width: 994px) {
        left: 49%;
      }

      @media (max-width: 1200px) {
        bottom: 600px;
      }

      @media (max-width: 1164px) {
        bottom: 535px;
      }
      @media screen {
      }

      .arrow {
        position: fixed;
        top: 333px;
        right: 371px;
        transition: 0.7s;

        @media (max-width: 1670px) {
          top: 355px;
          right: 190px;
        }

        @media (max-width: 1530px) {
          top: 334px;
          right: 190px;
        }

        @media (max-width: 1420px) {
          top: 313px;
          right: 230px;
        }

        @media (max-width: 1256px) {
          width: 169px;
          transform: rotate(20deg);
        }

        @media (max-width: 1164px) {
          width: 130px;
          top: 364px;
          right: 203px;
        }

        @media (max-width: 1084px) {
          top: 424px;
          right: 100px;
        }

        @media (max-width: 1040px) {
          right: 56px;
        }

        @media (max-width: 960px) {
          top: 441px;
          right: 41px;
          width: 97px;
        }

        @media (max-width: 840px) {
          transform: rotate(257deg);
          top: 430px;
          right: 148px;
        }

        @media (max-width: 769px) {
          display: none;
        }
      }

      .smallArrow {
        display: none;

        @media (max-width: 769px) {
          display: block;
          position: relative;
          right: 100px;
          bottom: 69px;
        }

        @media (max-width: 576px) {
          bottom: 40px;
        }

        @media (max-width: 490px) {
          right: 0px;
        }

        @media (max-width: 480px) {
          bottom: 0;
        }
      }

      &:hover {
        & > h1 {
          color: $white;

          & > span {
            color: $orange;
          }
        }

        .arrow {
          & > path {
            transition: 0.7s;

            stroke: $orange;
          }
        }

        .smallArrow {
          & > path {
            transition: 0.7s;

            stroke: $orange;
          }
        }
      }

      @media (max-width: 769px) {
        bottom: 0;
        left: 20%;
      }

      @media (max-width: 576px) {
        left: 2%;
      }
    }

    &_desc__left {
      background: transparent;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 7px;

      & h1 {
        width: 100%;
        padding-top: 10px;
        color: $darkGrey;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        transition: all 0.5s;

        span {
          color: $white;
          transition: all 0.5s;
        }
      }

      & p {
        background: transparent;
        color: $white;
        font-size: 18px;
        font-style: normal;
        font-weight: 100;
        line-height: normal;
        max-width: 388px;
      }

      @media (max-width: 1420px) {
        & h1 {
          padding-top: 120px;
          // font-size: 32px;
        }

        & p {
          width: 388px;
        }
      }

      @media (max-width: 1275px) {
        & h1 {
          padding-top: 180px;
        }
      }

      @media (max-width: 1164px) {
        margin-top: 44px;
      }

      @media (max-width: 1100px) {
        margin-top: 70px;
      }
      @media (max-width: 480px) {
        margin-top: 36px;
      }

      @media (max-width: 1080px) {
        // & h1 {
        //   font-size: 28px;
        // }

        & P {
          width: 330px;
        }
      }

      @media (max-width: 960px) {
        // & h1 {
        //   font-size: 25px;
        // }

        & P {
          width: 280px;
        }
      }

      @media (max-width: 768px) {
        & h1 {
          padding-top: 0;
        }

        &:last-child {
          margin-top: 110px;
        }

        & h1 > h4,
        & h1 > h5 {
          width: 590px;
          white-space: nowrap;
          font-size: 36px;
          font-weight: 700;
        }

        // & p {
        //   width: 570px;
        //   font-weight: 300;
        //   margin-top: 17px;
        // }
      }

      @media (max-width: 576px) {
        // & p {
        //   width: 420px;
        //   font-size: 16px;
        // }
      }

      @media (max-width: 490px) {
        & h1 {
          padding-top: 25px;
          font-size: 32px;
        }

        // & p {
        //   font-size: 14px;
        //   width: 400px;
        // }
      }

      @media (max-width: 480px) {
        &:last-child {
          margin-top: 0;
        }

        & h1 {
          font-size: 24px;
          padding-top: 0px;
        }

        & p {
          max-width: 298px;
        }
      }
    }

    &_img {
      background: transparent;

      height: 650px;
      position: relative;
      left: 29.2%;
      bottom: 281px;

      @media (max-width: 1420px) {
        width: 600px;
        height: 600px;
        left: 20.6%;
        bottom: 192px;
      }

      @media (max-width: 1275px) {
        width: 500px;
        height: 500px;
        left: 22.6%;
        bottom: 190px;
      }

      @media (max-width: 1164px) {
        bottom: 116px;
      }

      @media (max-width: 1100px) {
        bottom: 64px;
      }

      @media (max-width: 768px) {
        width: 553px;
        height: 550px;
        left: 46px;
        top: 240px;
      }

      @media (max-width: 576px) {
        left: 0;
      }

      @media (max-width: 431px) {
        width: 400px;
        height: 400px;
      }

      @media (max-width: 480px) {
        width: 342px;
        height: 340px;
        top: 180px;
      }
    }

    &_arrow {
      position: relative;
      bottom: 240px;
      background: none;

      &:hover {
        path {
          stroke: $orange;
        }
      }
    }
  }

  :global(.mySwiper) {
    @media (min-width: 769px) {
      &:hover + .hover {
        opacity: 1;
        position: relative;
        z-index: 1000;
        top: -170px;
      }
    }
  }

  .hover {
    opacity: 0;
    z-index: 1000;
    text-transform: uppercase;
    position: relative;
    top: -170px;
    font-size: 18px !important;

    @media (max-width: 769px) {
      opacity: 1 !important;
      position: relative;
      top: -137px;
      left: 22%;
      font-size: 18px;
    }

    @media (max-width: 576px) {
      top: -137px;
      left: 10%;
    }

    @media (max-width: 480px) {
      top: -137px;
      left: 3%;
      margin: auto;
    }

    @media (max-width: 480px) {
      top: -37px;
      left: 0%;
    }

    &:hover {
      opacity: 1 !important;
      z-index: 1000;
    }
  }
}

.swiperPaginationBullet {
  display: inline-block;
  width: 31px;
  height: 9px;
  border-radius: 4px;
  background-color: $darkGrey;
}

.swiperPaginationBullet:not(:last-child) {
  margin-right: 1rem;
}

@media (min-width: 768px) {
  .swiperPaginationBullet {
    margin-top: 10.3rem;
    margin-bottom: 2rem;
  }
}

@media (max-width: 768px) {
  .wrapper {
    :global {
      .mySwiper {
        height: auto;
      }

      .swiper-slide {
        height: 1757px;
      }

      .swiper-pagination-fraction,
      .swiper-pagination-custom,
      .swiper-horizontal > .swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 178px;
        left: 145px;
        display: flex;
        width: 100%;
        gap: 17px;

        @media (max-width: 576px) {
          left: 108px;
        }

        @media (max-width: 480px) {
          left: 33px;
          bottom: 92px;
        }
      }
    }
  }
}

.wrapper {
  :global {
    .swiper-slide {
      height: 1577px;

      // @media (max-width: 431px) {
      //   height: 1443px;
      // }

      @media (max-width: 500px) {
        height: 1300px;
      }

      @media (max-width: 480px) {
        height: 1070px;
      }
    }
  }
}

.secondText {
  background: transparent;
  color: $white;
  font-size: 18px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  max-width: 388px;

  @media (max-width: 450px) {
    font-size: 14px;
    max-width: 334px;
  }
}

.slide_text {
  color: #313131;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  transition: 0.5s;

  @media (max-width: 480px) {
    max-width: 334px;
    font-size: 24px;
    line-height: 29.05px;
  }
}

.color_orange {
  color: #ff6b00 !important;
  transition: 0.5s;
}

.color_white {
  color: $white;
  transition: 0.5s;
}

.slide_desc__right:hover {
  .color_white {
    color: #ff6b00 !important;
  }
  .slide_text {
    color: $white !important;
  }
}

.button_submite {
  z-index: 1000;
  text-transform: uppercase;
  position: relative;
  top: -170px;
  font-size: 18px !important;
}
