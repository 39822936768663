@import "../../styles/vars.scss";

.button {
  background-color: $orange;
  padding: 20px 22px 19px 22px;
  border: none;
  outline: none;
  border-radius: 4px;

  display: flex;
  align-items: center;
  gap: 43px;
  cursor: pointer;
  margin-bottom: 15px;

  .form_btn {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;

    & > h3 {
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }

    & > p {
      width: auto;
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
    }
  }

  .application_btn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: auto;

    & > h3 {
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }

    & > p {
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  p {
    color: #000;
    width: 185px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
  }

  svg {
    width: 41px;
    height: 41px;
  }
}

@media (max-width: 420px) {
  .button {
    .application_btn {
      width: 100%;
    }

    .form_btn {
      & > p {
        font-size: 14px;
      }
    }
  }
}
