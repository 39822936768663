@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;700&display=swap");
@import "./vars.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

body {
  background-color: $bg-color;
  overflow-x: hidden;
}

.container {
  max-width: 1598px;
  margin: 0 auto;

  @media screen and (min-width: 1024px) and (max-width: 1800px) {
    margin-left: 42px;
    margin-right: 42px;
  }
  @media screen and (max-width: 769px) {
    max-width: calc(100% - 18px);
  }
}

.swiper-button-prev,
.swiper-button-next,
.swiper-button-disabled {
  display: none;
}

* {
  outline: 1px red;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
