.lang_select {
  display: flex;
  gap: 16px;
  align-items: center;
  position: relative;
  padding: 27px 16px 27px 16px;
}

.background {
  @media (max-width: 530px) {
    background-color: rgba(27, 27, 27, 1);
  }
}

.switcher {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  height: 30px;
}

.options {
  display: flex;
  gap: 16px;
  align-items: center;
  position: absolute;
  visibility: visible;
  right: 75px;
  opacity: 1;
  list-style: none;
  transition: 0.5s;

  @media (max-width: 530px) {
    flex-direction: column;
    right: 0;
    top: 75px;
    padding: 0px 16px 27px 16px;
    background-color: rgb(27, 27, 27);
  }
}

.options_hide {
  position: absolute;
  right: 0;
  visibility: hidden;
  display: flex;
  list-style: none;
  opacity: 0;
  gap: 16px;
  transition: 0.5s;
  @media (max-width: 530px) {
    flex-direction: column;
    right: 0;
    top: 75px;
  }
}
