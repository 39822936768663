.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5000;

    .content {
        background: #151515;
        width: 40%;
        border-radius: 5px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        button {
            background-color: #ff6b00;
            padding: 20px 22px 19px 22px;
            border: none;
            outline: none;
            border-radius: 4px;
            display: flex;
            align-items: center;
            cursor: pointer;

            @media (max-width: 768px) {
                padding: 10px 11px 11px 11px;
            }

            p {
                color: #000;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;

                @media (max-width: 768px) {
                    font-size: 14px;
                }
            }
        }

        .modalContent {
            margin-top: 20px;

            p {
                color: #fff;
                font-size: 32px;
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 25px;
                text-align: center;

                @media (max-width: 768px) {
                    font-size: 18px;
                }
            }
        }
    }
}