@mixin basicInsoleImage {
  height: 400px;
  width: 700px;
  transition: 1s;
  position: relative;
  clip-path: polygon(
    3% 82%,
    6% 87%,
    13% 88%,
    22% 85%,
    33% 81%,
    45% 73%,
    55% 66%,
    64% 54%,
    72% 41%,
    82% 32%,
    90% 24%,
    96% 17%,
    97% 12%,
    95% 10%,
    89% 10%,
    82% 12%,
    74% 16%,
    67% 18%,
    60% 18%,
    53% 19%,
    48% 22%,
    41% 32%,
    37% 39%,
    32% 48%,
    28% 53%,
    20% 59%,
    15% 64%,
    8% 71%,
    4% 76%,
    3% 79%
  );
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (max-width: 960px) {
    height: 400px;
    width: 650px;
  }

  @media (max-width: 665px) {
    height: 200px !important;
    width: 340px !important;
  }

  @media (max-width: 1380px) {
    height: 250px;
    width: 400px;
  }
}
