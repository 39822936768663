.canvas {
  height: 707px !important;
  width: 711px !important;

  @media (max-width: 768px) {
    margin: 0 auto;
  }

  @media (max-width: 1200px) {
    height: 550px !important;
  }

  @media (max-width: 500px) {
    height: 340px !important;
  }
}
