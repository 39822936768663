@import "../../styles/vars.scss";

.footer {
  display: flex;
  justify-content: space-between;
  padding-top: 184px;
  padding-bottom: 108px;

  @media screen and (max-width: 769px) {
    padding-top: 20px;
    flex-direction: column-reverse;
  }

  @media (max-width: 420px) {
    padding-bottom: 45px;
    margin-top: 45px !important;
  }

  &_left {
    width: 525px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;

    @media screen and (max-width: 769px) {
      width: 100%;
    }

    @media screen and (max-width: 420px) {
      margin-top: 100px;
    }

    & > p {
      color: $white;
      font-size: 18px;
      font-style: normal;
      font-weight: 100;
      line-height: normal;
    }

    & > a {
      color: $link;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      text-decoration: none;
    }

    & > svg {
      margin-top: 59px;

      @media screen and (max-width: 420px) {
        margin-top: 0;
      }
    }
  }
}
