$orange: #ff6b00;
$darkGrey: #313131;
$white: #fff;
$darkGreen: #23503f;
$light_beige: #bba69e;
$dark_beige: #bca39a;
$darkGrey_v2: #292929;
$brown: #312517;
$bg-color: #151515;
$link: #565656;
$border: #2e2e2e;
$placeholder-color: #404040;
$sideBar-bg: #1b1b1b;
