@import "../../../styles/vars.scss";

.soleSlide {
  width: 523px;
  padding: 0 2rem;
  min-height: 775px;
  border-radius: 4px;
  background-color: #212121;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 13px;
  border-radius: 4px;
  background: #181818;
  padding-inline: 36px;
  padding-bottom: 34px;
  padding-top: 35px;
  min-height: 717px;
  max-height: 717px;
  transition: 0.4s;

  @media (max-width: 1081px) {
    padding-inline: 31px;
  }

  @media (max-width: 1036px) {
    padding-inline: 23px;
  }

  @media (max-width: 990px) {
    padding-inline: 28px;
  }

  @media (max-width: 800px) {
    padding-inline: 16px;
  }

  @media (max-width: 768px) {
    padding-inline: 64px;
  }

  @media (max-width: 590px) {
    padding-inline: 16px;
  }
}

.soleImage {
  max-width: 100%;
  height: auto;

  @media (max-width: 3040px) {
    max-width: 80%;
    margin: 0 auto;
  }

  @media (max-width: 2140px) {
    max-width: 100%;
    margin: 0;
  }
}

.soleSlideCaption {
  font-size: 12px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  color: white;
  text-transform: uppercase;
}

.soleSlideDescription {
  font-weight: 300;
  letter-spacing: 0em;
  color: white;
  color: rgba(255, 255, 255, 0.3);
}

.card_wrapper {
  height: 733px;
  margin-top: 44px;
  cursor: grab;

  &:hover + .hover_btn {
    visibility: visible;
    transition: visibility 0.4s;
  }
}

.card_wrapper:hover {
  & > .card {
    background: #212121;
  }
}

.soleColorList {
  margin-top: auto;

  // @media (max-width: 3040px) {
  //   margin-top: 15px;
  // }

  // @media (max-width: 2144px) {
  //   margin-top: auto;
  // }

  // @media (max-width: 1720px) {
  //   margin-top: 45px;
  // }

  // @media (max-width: 1275px) {
  //   margin-top: 20px;
  // }

  // @media (max-width: 1080px) {
  //   margin-top: 0;
  // }

  // @media (max-width: 991px) {
  //   margin-top: 81px;
  // }

  // @media (max-width: 900px) {
  //   margin-top: 0;
  // }

  // @media (max-width: 768px) {
  //   margin-top: 16px;
  // }

  // @media (max-width: 678px) {
  //   margin-top: 64px;
  // }
}

.soleColor {
  display: inline-block;
  width: 66px;
  height: 19px;
  border-radius: 2px;
  cursor: pointer;

  @media (max-width: 420px) {
    width: 88px;
    height: 24px;
  }
}

.hover_btn {
  visibility: hidden;
  transition: visibility 0.4s;
  text-transform: uppercase;

  &:hover {
    visibility: visible;
    transition: visibility 0.4s;
  }
  @media screen and (max-width: 475px) {
    visibility: visible !important;
    margin: auto;
  }
}

.soleColor:not(:last-child) {
  margin-right: 1rem;
}

.insoleSwiper {
  display: flex;
  flex-direction: column-reverse;
  overflow: visible;

  @media (max-width: 1670px) {
    overflow: hidden;
  }

  :global {
    .swiper-pagination {
      position: inherit;
      display: flex;
      bottom: 0;
    }
  }

  @media (max-width: 420px) {
    overflow: hidden;
  }

  :global {
    span.swiper-pagination-bullet-active {
      width: 90px;
      background-color: $orange;
    }

    @media (min-width: 768px) {
      .swiper-pagination {
        display: block;
        position: relative;
        left: -36.9%;
      }

      .soleColorList {
        :last-child {
          margin-top: 91px;
        }
      }
    }

    @media (min-width: 992px) {
      .swiper-pagination {
        display: none;
      }
    }

    // @media screen and (max-width: 391px) {
    //   .swiper-pagination {
    //     display: none;
    //   }
    // }
  }

  .swiperPaginationBullet {
    display: inline-block;
    width: 31px;
    height: 9px;
    border-radius: 4px;
    background-color: $darkGrey;
  }

  .swiperPaginationBullet:not(:last-child) {
    margin-right: 1rem;
  }
}

@media (min-width: 375px) {
}

@media (min-width: 768px) {
  .soleSlide {
    margin-top: 0;
  }

  .soleSlideCaption {
    margin-bottom: 0.8rem;
    font-size: 24px;
    line-height: 29px;
  }

  .soleSlideDescription {
    font-size: 16px;
    line-height: 19px;
  }

  .insoleSwiper {
    display: flex;
  }

  .swiperPaginationBullet {
    margin-top: 10.3rem;
    margin-bottom: 2rem;
  }
}

@media (max-width: 576px) {
  .soleColorList {
    margin-top: 81px;
  }
}

@media (max-width: 420px) {
  .soleColorList {
    margin-top: 81px;
  }
}

@media (min-width: 992px) {
  .soleSlide {
    margin-top: 14.5rem;
  }

  .soleSlideCaption {
    margin-bottom: 1rem;
    font-size: 34px;
    line-height: 44px;
  }

  .soleSlideDescription {
    font-size: 18px;
    line-height: 22px;
  }

  .insoleSwiper {
    margin: 0;
  }
}

@media (max-width: 1669px) {
  .soleSlideCaption {
    margin-bottom: 1rem;
    font-size: 33px;
    line-height: 44px;
  }
}

@media (max-width: 1635px) {
  .soleSlideCaption {
    margin-bottom: 1rem;
    font-size: 24px;
    line-height: 44px;
  }
}

@media (max-width: 1346px) {
  .soleSlideCaption {
    margin-bottom: 1rem;
    font-size: 24px;
    line-height: 44px;
  }
}

@media (max-width: 1284px) {
  .soleSlideCaption {
    margin-bottom: 1rem;
    font-size: 18px;
    line-height: 44px;
  }
}

@media (max-width: 1111px) {
  .soleSlideCaption {
    margin-bottom: 1rem;
    font-size: 18px;
    line-height: 44px;
  }
}

@media (max-width: 625px) {
  .soleSlideCaption {
    margin-bottom: 1rem;
    font-size: 16px;
    line-height: 44px;
  }
}

@media (max-width: 575px) {
  .soleSlideCaption {
    margin-bottom: 1rem;
    font-size: 24px;
    line-height: 44px;
  }
}

@media (max-width: 370px) {
  .soleSlideCaption {
    margin-bottom: 1rem;
    font-size: 16px;
    line-height: 44px;
  }
}
