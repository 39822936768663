@import "../../../styles/vars.scss";

.input {
  width: 525px;
  background: #1b1b1b;
}

.button {
  @media (max-width: 431px) {
    width: 100% !important;
    max-width: none !important;
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  &.noMargin {
    margin-right: 0px !important;
  }

  input {
    height: 61px;
  }

  textarea {
    height: 95px;
    resize: none;
  }

  & > input,
  textarea {
    width: 525px;
    border-radius: 4px;
    border: 1px solid $border;
    // background-color: $bg-color;
    background: #1b1b1b;
    padding-left: 22px;
    padding-top: 20px;
    padding-bottom: 17px;
    outline: none;
    font-size: 20px;
    color: $white;
    font-weight: 300;

    &::placeholder {
      color: $placeholder-color;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }

    @media screen and (max-width: 769px) {
      width: 100%;
    }
  }

  @media (max-width: 769px) {
    margin-bottom: 176px;
    margin-top: 30px;
  }
}

@import "../../../styles/vars.scss";

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  input {
    height: 61px;
  }

  textarea {
    height: 95px;
    resize: none;
  }

  & > input,
  textarea {
    width: 525px;
    border-radius: 4px;
    border: 1px solid $border;
    background-color: $bg-color;
    padding-left: 22px;
    padding-top: 20px;
    padding-bottom: 17px;
    outline: none;
    font-size: 20px;
    color: $white;
    font-weight: 300;

    &::placeholder {
      color: $placeholder-color;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }

    @media screen and (max-width: 769px) {
      width: 100%;
    }
  }

  @media (max-width: 769px) {
    margin-bottom: 176px;
    margin-top: 30px;
  }

  @media (max-width: 431px) {
    margin-right: 16px;
    margin-bottom: 30px;
    margin-top: 0;
  }

  @media (max-width: 420px) {
    margin-right: 16px;
  }
}

.textarea {
  height: auto;
  overflow: hidden;
  min-height: 87px;
  width: 100%;
}
