@import "../../styles/vars.scss";

.wrapper {
  background: url("../../assets/img/promo.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 55vh;
  padding-top: 113px;

  @media (max-width: 1920px) {
    height: 85vh;
  }

  @media screen and (max-width: 1500px) {
    padding-top: 96px;
    height: 100vh;
    padding-bottom: 20px;
  }

  @media screen and (max-width: 769px) {
    height: 81vh;
    width: 100%;
    background-position-x: center;
    padding-top: 80px;
  }

  @media screen and (max-width: 391px) {
    height: 96vh;
  }

  & > div {
    background: transparent;

    .title {
      background: transparent;

      color: #fff;
      font-size: 84px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;

      & > span {
        background: transparent;

        color: $orange;
      }

      @media screen and (max-width: 1220px) {
        font-size: 65px;
      }

      @media (max-width: 940px) {
        font-size: 50px;
      }

      @media screen and (max-width: 840px) {
        font-size: 48px;
      }

      @media screen and (max-width: 769px) {
        font-size: 48px;
      }

      @media screen and (max-width: 576px) {
        font-size: 40px;
      }

      @media screen and (max-width: 490px) {
        font-size: 24px;
      }

      @media screen and (max-width: 391px) {
        font-size: 24px;
      }
    }

    & > p {
      color: #fff;
      font-size: 18px;
      background: transparent;
      width: 525px;
      font-style: normal;
      font-weight: 100;
      line-height: normal;

      margin-left: 190px;
      margin-top: 20px;

      @media (max-width: 810px) {
        margin-left: 100px;
      }

      @media screen and (max-width: 769px) {
        margin-left: 109px;
      }

      @media screen and (max-width: 640px) {
        margin-left: 70px;
      }

      @media screen and (max-width: 600px) {
        margin-left: 35px;
      }

      @media screen and (max-width: 576px) {
        margin-left: 30px;
        width: 440px;
      }

      @media screen and (max-width: 490px) {
        font-size: 16px;
        margin-left: 0;
        margin-right: 20px;
      }

      @media screen and (max-width: 450px) {
        margin-right: 0px;
        width: 369px;
      }

      @media screen and (max-width: 420px) {
        font-size: 14px;
        width: 343px;
      }
    }

    @media screen and (max-width: 490px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }
}
