@import "../../styles/vars.scss";

.wrapper {
  :global {
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      bottom: 53px;
      left: 165px;
      display: flex;
      width: 100%;

      @media (max-width: 768px) {
        bottom: 41px;
        left: 41px;
      }

      @media (max-width: 576px) {
        bottom: 30px;
        left: 10px;
      }

      @media (max-width: 960px) {
        bottom: 30px;
      }
    }

    span.swiper-pagination-bullet-active {
      width: 90px;
      background-color: $orange;
    }
  }

  @media (max-width: 769px) {
    margin-top: 92px;
  }
}

.title {
  font-size: 112px;
  color: $white;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 15px;
  margin-left: 300px;
  margin-right: 650px;

  @media (max-width: 769px) {
    font-size: 93px;
    margin-left: 150px;
    margin-right: 325px;
  }

  @media (max-width: 1366px) {
    font-size: 88px;
  }

  @media (max-width: 480px) {
    font-size: 48px;
    margin-left: 75px;
    margin-right: 170px;
  }
}

// .title {
//   width: 175vw;
//   overflow: hidden;
//   white-space: nowrap;
//   font-size: 143px;

//   translate: -32px;
//   color: $white;
//   font-style: normal;
//   font-weight: 700;
//   line-height: normal;
//   text-transform: uppercase;

//   @media (max-width: 2000px) {
//     text-align: center;
//     margin-bottom: 35px;
//   }

//   @media (max-width: 960px) {
//     margin-bottom: 36px;
//   }

//   @media (max-width: 769px) {
//     width: 104vw;
//     margin-bottom: 25px;
//     font-size: 96px;
//   }

//   @media (max-width: 576px) {
//     font-size: 72px;
//   }

//   @media (max-width: 420px) {
//     font-size: 48px;
//     width: 246vw;
//   }
// }

.swiperPaginationBullet {
  display: inline-block;
  width: 31px;
  height: 9px;
  border-radius: 4px;
  background-color: #313131;
}

.swiperPaginationBullet:not(:last-child) {
  margin-right: 1rem;
}

@media (min-width: 768px) {
  .swiperPaginationBullet {
    margin-top: 10.3rem;
    margin-bottom: 2rem;
  }
}

.slide {
  background-image: url("../../assets/img/healthyJourney.png");
  height: 87vh;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  @media (max-width: 768px) {
    height: 77vh;
  }
}

.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 525px;

  position: absolute;
  bottom: 110px;
  right: 130px;

  & > h1 {
    color: $orange;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;

    span {
      color: #fff;
    }
  }

  & > p {
    color: $white;
    font-size: 18px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
  }

  @media (max-width: 769px) {
    bottom: 81px;
    right: 15px;
  }

  @media (max-width: 576px) {
    bottom: 227px;
    right: -112px;

    & > h1 {
      font-size: 24px;
    }

    & > p {
      font-size: 14px;
      width: 315px;
      font-weight: 300;
    }
  }

  @media (max-width: 440px) {
    width: 506px;
  }

  @media (max-width: 420px) {
    width: 469px;

    & > p {
      width: 315px;
    }
  }
}
